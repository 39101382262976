import React, { Component } from 'react'

export default class SkillCard extends Component {

    render() {
        return (
            <div className="skills-wrapper columns">
                <div className="column">
                    <div className='card-wrapper'>
                        <h2>Development</h2>
                        <ul>
                            <li>Javascript</li>
                            <li>HTML5</li>
                            <li>CSS3/Stylus/SASS/Bulma</li>
                            <li>React/React Native</li>
                            <li>ES6</li>
                            <li>Babel</li>
                            <li>REST API endpoints</li>
                            <li>Optimization</li>
                            <li>Responsive design(mobile friendly)</li>
                            <li>npm</li>
                            <li>Git</li>
                            <li>jQuery</li>
                            <li>Node</li>
                            <li>Webpack</li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className='card-wrapper'>
                        <h2>Non-Technical</h2>
                        <ul>
                            <li>Self-directed learning</li>
                            <li>Situation-Awareness</li>
                            <li>Resilience</li>
                            <li>Collaboration</li>
                            <li>Communicate and Interface with others</li>
                            <li>Empathy</li>
                            <li>Task Management</li>
                            <li>Self-Disciplined</li>
                            <li>Self-Motivated</li>
                            <li>Integrity</li>
                            <li>Responsible</li>
                            <li>Respectful</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}