import React, { Component } from 'react'
import SkillCard from './skill-card'

class Skills extends Component {

    render() {
        return (
            <div>
                <h1>Skills</h1>
                <SkillCard/>
            </div>
        )
    }
}

export default Skills