import React, { Component } from 'react'
import ContactInfo from './contact-info'

class Contact extends Component {
    constructor() {
        super()

        this.state = {
            loading: true,
            auth: false
        }
    }

    render() {
        return (
           <div>
               <ContactInfo/>
           </div>
        )
    }
}

export default Contact