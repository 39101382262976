import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import App from './components/App'
import './static/css/index.css'

function AppRouter() {
  return (
    <Router>
        <Route path="/" component={App} />
    </Router>
  )
}

ReactDOM.render(<AppRouter/>, document.getElementById("root"))