import React, { Component } from 'react'

export default class Intro extends Component {

    render() {
        return (
            <div className="intro columns">
                <div className="column">
                    <h1 style={intro}>
                        <div style={intro}>Hang in there, our website is coming soon.</div>
                        <div style={intro}>In the meantime please contact us:</div>
                        <a style={intro} href="tel:8013588120">801-358-8120</a>
                    </h1>
                </div>
            </div>
        )
    }
}
const intro = {
    fontSize: '45px',
    color: '#fff'
}