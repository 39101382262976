import React, { Component } from 'react'

class PageNotFound extends Component {

    render() {
        return (
           <div>
               PageNotFound
           </div>
        )
    }
}

export default PageNotFound