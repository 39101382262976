import React, { Component } from 'react'

class BurgerMenu extends Component {
    constructor() {
        super()
        this.state = {
            active: false
        }
    }

    toggleMenu = () => {
        const menu = document.getElementById('menu')
        const burgerMenu = document.getElementById('burger-menu')
        const navBar = document.getElementById('navbar')
        const body = document.getElementById('body')
        if (menu.classList.contains('active')) {
            menu.classList.remove('active')
            body.classList.remove('state-fixed')
            burgerMenu.classList.remove('active')
            navBar.classList.remove('active')
        } else {
            menu.classList.add('active');
            body.classList.add('state-fixed');
            burgerMenu.classList.add('active');
            navBar.classList.add('active');
        }
    }

    render() {
        // const menuState = `${(this.state.active ? 'active' : '')}`
        // <div id='burger-menu' className={`icon hamburger-menu ${menuState}`} onClick={this.toggleMenu}>
        return (
            <div className='hamburger-menu-wrapper'>
                <div id='burger-menu' className='icon hamburger-menu' onClick={this.toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        )
    }
}

export default BurgerMenu