import React, { Component } from 'react'

export default class ContactInfo extends Component {
    render() {
        return (
            <div className="sentence-wrapper">
                <h3>Contact Me</h3>
                <a href="tel:8016366741">801-636-6741</a>
                <div>ethandaley4@gmail.com</div>
            </div>
        )
    }
}