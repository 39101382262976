import React, { Component } from 'react'
import WorkExperience from './work-experience'
class Work extends Component {
    constructor() {
        super()

        this.state = {
            loading: true,
            auth: false
        }
    }

    render() {
        return (
           <div>
               <WorkExperience/>
           </div>
        )
    }
}

export default Work