import React, { Component } from 'react'
import AboutMe from './about-me'
// import OutSideWork from './outside-work'
// import References from './references'

class About extends Component {
    constructor() {
        super()

        this.state = {
            loading: true,
            auth: false
        }
    }

    render() {
        return (
           <div>
               <AboutMe/>
               {/* <OutSideWork/> */}
               {/* <References/> */}
           </div>
        )
    }
}

export default About