import React, { Component } from 'react'
import Intro from './intro'

export default class Home extends Component {

	render() {
		return (
			<div className='home'>
				<Intro />
			</div>
		)
	}
}