import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './navbar'
import Home from './home'
import About from './about'
import Skills from './skills'
import Work from './work'
import Contact from './contact'
import PageNotFound from './error'

class App extends Component {

    render() {
        return (
            <Router>
                <Navbar />
                <div className="page-body">
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/skills" component={Skills} />
                        <Route path="/work" component={Work} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/about" component={About} />
                        <Route component={PageNotFound} />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App