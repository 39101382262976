import React, { Component } from 'react'

export default class AboutMe extends Component {

    render() {
        return (
            <div className="sentence-wrapper">
                <h3>About Me</h3>
                <p>Talented frontend team manager with 5+ years of proven know-how of combining creativity and usability to create professional web apps. I am constantly observing others for new techniques and technologies to improve my skillset.</p>
            </div>
        )
    }
}