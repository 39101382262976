import React, { Component } from 'react'
import oldglorycurbing from '../../assets/images/projects/old-glory-curbing.jpg'
import bellalash from '../../assets/images/projects/bella-lash.jpg'
import microfocus from '../../assets/images/projects/microfocus.jpg'
import ekr from '../../assets/images/projects/ekr.jpg'
import suse from '../../assets/images/projects/suse.jpg'
import loopminded from '../../assets/images/projects/loopminded.jpg'

export default class WorkExperience extends Component {
    constructor(props) {
        super()
        this.state = {
            projects: [
                { name: 'Loopminded', url: 'https://loopminded.com/', image: loopminded},
                { name: 'Bella Lash', url: 'https://bellalash.com/', image: bellalash },
                { name: 'Old Glory Curbing', url: 'https://oldglorycurbing.com/', image: oldglorycurbing },
                { name: 'Micro Focus', url: 'https://www.microfocus.com/en-us/home', image: microfocus },
                { name: 'EKR', url: 'https://ekragency.com/', image: ekr },
                { name: 'Suse', url: 'https://www.suse.com/index-b/?campaign_description=FY19%20November%20Global%20GMOC%20Advertising%20online%20SEMPPC%20Enterprise%20Linux&campaign_id=GSDGNDGEL34808&gclid=CjwKCAjw7anqBRALEiwAgvGgm157SvuUOwuRuN-OwkO0c7iFDo5SkzsFIXXFYyc1VzqdsrziFtgjvBoCnWQQAvD_BwE', image: suse },
            ]
        }
    }
    render() {
        const projects = this.state.projects.map((project, i) => {
            const bgImage = {
                backgroundImage: `url(${project.image})`
            };
            return (
                <div className='outer-overflow'>
                    <a href={project.url} target='_blank' rel="noopener noreferrer" style={bgImage} className='outer'>
                        <div className='inner'>
                            <span>{project.name}</span>
                        </div>
                    </a>
                </div>
            )
        })
        return (
            <div className='work-experience'>
                <h1>Work Experience</h1>
                <div className='columns'>
                    <div className='column'>
                        <div className='card-wrapper'>
                            <h2>Senior Frontend Web Develper</h2>
                            <h3>Loopminded and Bella Lash | Aug 2017 - Present</h3>
                            <ul>
                                <li>Developed SkillSuite and Warehouse Managment Sofware in React using Django REST Framework.</li>
                                <li>Overhauled Loopminded.com and Loopminded's customer portal.</li>
                                <li>Bella Lash's Website rebuilt from the ground up for a custom user experience, worked directly with our backend team who build a custom E-commerce for bellalash.com.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='card-wrapper'>
                            <h2>Web Develper</h2>
                            <h3>Freelance Web Developer | Jul 2012 - Current</h3>
                            <ul>
                                <li>Build static websites for clients.</li>
                                <li>Work with clients on SEO and google ads words.</li>
                                <li>Run clients sites on Nginx servers.</li>
                                <li>Maintain a professional relation with clients.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='card-wrapper'>
                            <h2>Web Develper</h2>
                            <h3>Micro Focus and EKR | May 2015 - Aug 2017</h3>
                            <ul>
                                <li>Overhauled Micro Focus’s website to optimize the user experience.</li>
                                <li>Developed Trial downloads for a optimized user experience when demoing a product.</li>
                                <li>Worked directly with project managers and steakholders to create custom pages.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h2 className='section-title'>Projects</h2>
                <div className='projects-wrapper'>
                    {projects}
                    {/* <div className='outer'>
                        <div className='inner'>
                            <span>Bella Lash</span>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}