import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Menu from './menu'
import BurgerMenu from './burger-menu.js'

class Navbar extends Component {
    constructor() {
        super()

        this.state = {
            loading: true,
            auth: false
        }
    }

    render() {
        return (
            <div>
                <header id='navbar' className="navbar">
                    <Link to='/' style={logoStyle}>Industrial Floor Design</Link>
                    {/* <BurgerMenu /> */}
                </header>
                <Menu />
            </div>
        )
    }
}

export default Navbar

const logoStyle = {
    fontSize: '28px',
    textDecoration: 'none',
    color: '#fff'
}