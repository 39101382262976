import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Menu extends Component {
    constructor() {
        super()
        this.state = {
            active: false
        }
    }

    toggleMenu = () => {
        const menu = document.getElementById('menu')
        const burgerMenu = document.getElementById('burger-menu')
        const navBar = document.getElementById('navbar')
        const body = document.getElementById('body')
        menu.classList.remove('active')
        body.classList.remove('state-fixed')
        navBar.classList.remove('active')
        burgerMenu.classList.remove('active')
    }

    render() {
        return (
            <div>
                <div id='menu' className='menu'>
                    <div>
                        <div className='menu-items'>
                            <Link to='/' onClick={this.toggleMenu}>Home</Link>
                        </div>
                        <div className='menu-items'>
                            <Link to='/skills' onClick={this.toggleMenu}>Skills</Link>
                        </div>
                        <div className='menu-items'>
                            <Link to='/work' onClick={this.toggleMenu}>Work</Link>
                        </div>
                        <div className='menu-items'>
                            <Link to='/about' onClick={this.toggleMenu}>About</Link>
                        </div>
                        <div className='menu-items'>
                            <Link to='/contact' onClick={this.toggleMenu}>Contact</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu